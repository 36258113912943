import { Button, Card, Divider, Flex, List, Popover, Typography } from "antd";
import { EditTwoTone, ReadOutlined } from "@ant-design/icons";
import { Announcement } from "..";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import Markdown from "react-markdown";
import { formatDateTimeISO } from "../../../utils/date";
import useFetchUsername from "../../../hooks/useFetchUsername";
import { useState } from "react";
import EditAnnouncementModal from "./EditAnnouncementModal";
import AttachmentsSection from "./AttachmentsSection";

interface ContentProps {
  announcement: Announcement;
  onAcknowledge: () => void;
  onTriggerRefresh: () => void;
  onDelete: () => void;
  isAnnouncer: boolean;
}

function PopoverAckList(props: { readBy: string[] }) {
  return (
    <div>
      { props.readBy.length === 0 && <em>No one</em> }
      { props.readBy.map((user, idx) =>
        <p key={idx}>{user}</p>
      )}
    </div>
  )
}

export default function Content(props: ContentProps) {
  const uname = useFetchUsername();
  const isAcknowledged = props.announcement.readBy.includes(uname);
  const [isEditing, setIsEditing] = useState(false);

  function handleEdit() {
    setIsEditing(true);
  }

  return (<>
    <Card
      title={
        <Flex justify="space-between" align="center">
          <Typography.Title level={4} style={{ marginTop: "0.5em" }}>
            {props.announcement.title}
          </Typography.Title>
          { props.isAnnouncer && uname === props.announcement.author && <Button icon={<EditTwoTone />} onClick={handleEdit} /> }
        </Flex>
      }
      actions={[
        <Popover
          content={<PopoverAckList readBy={props.announcement.readBy} />}
          title={"Acknowledged by"}
          placement="bottom"
        >
          <Button icon={<ReadOutlined />}>Acknowledged by {props.announcement.readBy.length} user{props.announcement.readBy.length === 1 ? "" : "s"}</Button>
        </Popover>,
        props.announcement.author === uname ? (
          <Button danger onClick={props.onDelete}>Delete</Button>
        ) : (
          <Button
          type="primary"
          onClick={props.onAcknowledge}
          disabled={isAcknowledged}
        >
          { isAcknowledged ? "Acknowledged" : "Acknowledge" }
        </Button>
        )
      ]}
    >
      <Typography.Text type="secondary" italic>
        Posted by {props.announcement.author} on {formatDateTimeISO(props.announcement.createdAt)}
      </Typography.Text>
      <Markdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>
        {props.announcement.content}
      </Markdown>

      { props.announcement.attachments.length > 0 && (
        <AttachmentsSection attachments={props.announcement.attachments} />
      )}
    </Card>
    <EditAnnouncementModal
      visible={isEditing}
      announcement={props.announcement}
      onDismiss={() => setIsEditing(false)}
      onSubmitted={() => { setIsEditing(false); props.onTriggerRefresh(); }}
    />
  </>)
}
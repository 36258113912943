import { useEffect, useState } from "react";
import { Table, Input, Button, Spin, Row, Col, Typography, Tag } from "antd";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import axios from "axios";
import { formatDateISO, formatDateTimeISO } from "../../../utils/date";
import RedemptionDetailsModal from "./RedemptionDetailsModal";
const { Text } = Typography;

const REDEMPTIONS_ADMIN_API_PROD_URL = "https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/sb-redemption/admin/redeem";
const REDEMPTIONS_ADMIN_API_DEV_URL = "https://wv90e9zk2m.execute-api.ap-southeast-1.amazonaws.com/test/sb-redemption/admin/redeem"

const REDEMPTIONS_ADMIN_API_URL = process.env.NODE_ENV === "production" || process.env.VERCEL_ENV === "production" ? REDEMPTIONS_ADMIN_API_PROD_URL : REDEMPTIONS_ADMIN_API_DEV_URL;

export interface RedemptionData {
  kycSubmittedBy: string | null;
  orderId: string;
  updatedAt: string;
  status: string;
  createdAt: string;
  vendorId: string;
  simIdx: number;
  productId: string;

  assignedSimSn: string | undefined;
  orderEmail: string | undefined;
}

export interface RedemptionGroupedData {
  orderId: string;
  orderDate: string;
  status: string;
  orderEmail: string | undefined;
  kycs: RedemptionData[];

  redeemedCount: number;
}

export default function SBRedemptions() {
  const [data, setData] = useState<RedemptionGroupedData[]>([]);
  const [loading, setLoading] = useState(true);
  const { Title } = Typography;
  const [search, setSearch] = useState("");

  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [detailsData, setDetailsData] = useState<RedemptionGroupedData | null>(null);

  useEffect(() => {
    if (search === "") {
      getData();
    }
  }, [search]);

  async function getData() {
    // Coming soon
    const resKyc = await axios.get(REDEMPTIONS_ADMIN_API_URL);
    const kycs: RedemptionData[] = resKyc.data;

    // Group data by order number
    const tmp: { [key: string]: RedemptionData[] } = {};
    for (const kyc of kycs) {
      if (tmp[kyc.orderId] === undefined) {
        tmp[kyc.orderId] = [kyc];
      } else {
        tmp[kyc.orderId].push(kyc);
      }
    }

    // Convert to array
    const output: RedemptionGroupedData[] = [];
    for (const k of Object.keys(tmp)) {
      const redeemedCount = tmp[k].filter((kyc) => kyc.status === "REDEEMED").length;
      const order: RedemptionGroupedData = {
        orderId: k,
        orderDate: tmp[k][0].createdAt,
        status: `${redeemedCount}/${tmp[k].length}`,
        redeemedCount: redeemedCount,
        orderEmail: tmp[k][0].orderEmail,
        kycs: tmp[k],
      };
      output.push(order);
    }

    output.sort((a, b) => a.orderDate.localeCompare(b.orderDate)).reverse();
    console.log("grouped data", output);
    setData(output);
    setLoading(false);
  };

  function handleViewDetails(record: RedemptionGroupedData) {
    // Coming soon
    setDetailsData(record);
    setIsDetailsModalVisible(true);
  }

  /**
   * Configure columns for table
   */
  const columns: ColumnsType<RedemptionGroupedData> = [
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      // width: 90,
      sorter: (a, b) =>
        a.orderDate?.localeCompare(b.orderDate!!) ?? -1,
      render: (date: string) => formatDateTimeISO(date),
    },
    {
      title: "Online Order Number",
      dataIndex: "orderId",
      key: "orderId",
      // width: 100,
      sorter: (a, b) => a.orderId.localeCompare(b.orderId),
      render: (orderNumber: string) => orderNumber === "" ? "-" : orderNumber,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // width: 120,
      sorter: (a, b) => a.redeemedCount - b.redeemedCount,
      render: (status: string) => {
        const parts = status.split("/");
        const [redeemed, total] = parts.map((x) => parseInt(x));

        let color = "orange";
        if (redeemed === total) {
          color = "green";
        }

        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Order Email",
      dataIndex: "orderEmail",
      key: "orderEmail",
      // width: 100,
      sorter: (a, b) => a.orderEmail?.localeCompare(b.orderEmail!!) ?? -1,
      render: (email) => email ?? "-",
    },
    {
      title: "Action",
      key: "operation",
      // width: 100,
      render: (text, record) => (
        <>
          <Button
            type="primary"
            onClick={() => handleViewDetails(record)}
          >
            View Details
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" gutter={8}>
        <Col flex="auto">
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value.trim())}
            placeholder="Search all fields..."
            onPressEnter={getData}
          />
        </Col>
        <Col>
          <Button onClick={getData} icon={<SearchOutlined />}>
            Search
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={15}>
          <Title level={3}>Self-Service Redemption History</Title>
        </Col>

        <Col span={9} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{ float: "right", margin: "5px", marginTop: "20px" }}
            type="primary"
            shape="round"
            icon={<ReloadOutlined />}
            onClick={getData}
            size="large"
          />
        </Col>
      </Row>
      <Row>
        <Text>{loading ? "Loading..." : `Total: ${data.length} records`}</Text>
      </Row>
      <Table
        columns={columns}
        dataSource={data}
        loading={{
          indicator: (
            <div>
              <Spin />
            </div>
          ),
          spinning: loading,
        }}
        pagination={{
          defaultPageSize: 20,
          pageSizeOptions: [10, 20, 50, 100],
          defaultCurrent: 1
        }}
        size="small"
        bordered
        showHeader
      />

      <RedemptionDetailsModal
        isModalVisible={isDetailsModalVisible}
        setIsModalVisible={setIsDetailsModalVisible}
        data={detailsData}
      />

    </>
  );
}

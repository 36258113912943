import { Button, Card, Flex, Spin, Table, TableProps, Typography } from "antd";
import { Announcement, useGetAnnouncementsQuery } from "../../../api/announcementApi";
import { formatDateISO } from "../../../utils/date";
import { useNavigate } from "react-router-dom";

export default function AnnouncementsCard() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetAnnouncementsQuery();

  const columns: TableProps<Announcement>["columns"] = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => formatDateISO(date),
      width: 150
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Action",
      key: "action",
      render: (_, ann) => (
        <Button
          onClick={() => navigate(`/announcements?id=${ann.id}`)}
        >
          Read
        </Button>
      ),
      width: 0.1
    }
  ]

  return (
    <Card title="Recent Announcements" style={{ width: "100%", height: "100%" }}>
      { isLoading &&
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Spin />
        </Flex>
      }
      { error && <Typography.Text>Error loading announcements</Typography.Text> }
      { data &&
        <Table<Announcement>
          columns={columns}
          dataSource={data.slice(0, 5)}
          rowKey="id"
          pagination={false}
          size="small"
        />
      }
    </Card>
  )
}
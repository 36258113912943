import {
    Breadcrumb,
    Layout,
    Menu,
    theme,
    Button,
    Space,
    Checkbox,
    Form,
    Input,
    Typography,
    Flex,
  } from "antd";
  import echoWhite from '../components/assets/logo-white.png';
import { MenuButton } from "@aws-amplify/ui-react";
import { Auth } from 'aws-amplify';
import { useEffect, useState } from "react";
import dayjs from "dayjs";

const { Text } = Typography;


export default function NavBar() {
    const [uname, setUname] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");

    useEffect(() => {
        const fetchUser = async () => {
          let session = await Auth.currentUserInfo();
          console.log('User info Session', session.username);
          setUname(session.username);
        };
        fetchUser();
      }, []);

      useEffect(() => {
        const updateDateTime = () => {
          const now = new Date();
          const formattedDate = dayjs(now).format("DD MMM YYYY");
          const formattedTime = `${String(now.getHours()).padStart(2, "0")}:${String(
            now.getMinutes()
          ).padStart(2, "0")}:${String(now.getSeconds()).padStart(2, "0")}`;
          setDate(formattedDate);
          setTime(formattedTime);
        };

        // Update every second
        const intervalId = setInterval(updateDateTime, 1000);

        // Cleanup on unmount
        return () => clearInterval(intervalId);
      }, []);

    async function signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <Flex justify="space-between" align="center" flex={1}>
            <Flex gap={64}>
                <Flex vertical align="center">
                    <Text strong>Echo Networks</Text>
                    <Text>{uname}</Text>
                </Flex>
                <Flex align="center" vertical>
                    <Text>{date}</Text>
                    <Text>{time}</Text>
                </Flex>
            </Flex>
            <Flex gap={32}>
                {/* Coming soon for distributor logins only */}
                {/* <Flex vertical align="center" justify="center"> */}
                    {/* <Text strong>Credit Balance: SGD$0.00</Text> */}
                {/* </Flex> */}
                <Flex vertical align="center">
                    <Button type="primary" onClick={signOut}>
                        Logout
                    </Button>
                </Flex>
            </Flex>
        </Flex>
        // {/* <div className="logo" > */}
        //     {/* <img height="50px" width="130px" src={echoWhite} alt="Echo" /> */}
        // {/* </div> */}
    )

}
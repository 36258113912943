import React, { useContext, useEffect, useRef, useState } from 'react';
import { Table, Space, Input, Dropdown, Button, message, Spin, Modal, Tabs, Row, Col, Typography, Tag } from 'antd';
import type { InputRef } from 'antd';
import type { MenuProps } from 'antd';
import { DownOutlined, UserOutlined, SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import Highlighter from 'react-highlight-words';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import axios from 'axios'
import Dropzone from "react-dropzone-uploader";
import 'react-dropzone-uploader/dist/styles.css'


const SB_DATA_MAPPING_UPLOADFILE_PROD_URL = "https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/vn-data-mapping/uploadfile"
const SB_DATA_MAPPING_UPLOADFILE_TEST_URL = SB_DATA_MAPPING_UPLOADFILE_PROD_URL;
const SB_DATA_MAPPING_STATUS_PROD_URL = "https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/vn-data-mapping/status"
const SB_DATA_MAPPING_STATUS_TEST_URL = SB_DATA_MAPPING_STATUS_PROD_URL;

const sbDataMappingUploadfileUrl = (process.env.NODE_ENV === 'production' || process.env.VERCEL_ENV === "production") ? SB_DATA_MAPPING_UPLOADFILE_PROD_URL : SB_DATA_MAPPING_UPLOADFILE_TEST_URL
const sbDataMappingStatusUrl = (process.env.NODE_ENV === 'production' || process.env.VERCEL_ENV === "production") ? SB_DATA_MAPPING_STATUS_PROD_URL : SB_DATA_MAPPING_STATUS_TEST_URL

export default function VNMapping() {
    let objectid_store = ""
    let uploadurl_link = ""

    const [err, setErr] = useState('');
    const [fileState, setFileState] = useState('')
    const [data, setData] = useState([]);
    const [loading, setloading] = useState(true)
    const { Title } = Typography;

    useEffect(() => {
        fetchData();
    },[fileState]);

    // Columns configuration
    const columns = [
        {
            title: 'Job Name',
            dataIndex: 'jobName',
            key: 'jobName',
        },
        {
            title: 'Run Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => { // Explicitly type the 'status' parameter as string
                let color = 'volcano';  // Default to red for failed jobs
                if (status === 'SUCCESS') color = 'green';
                else if (status === 'RUNNING') color = 'blue';

                return <Tag color={color}>{status}</Tag>;
            }
        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            key: 'startTime',
        },
        {
            title: 'End Time',
            dataIndex: 'endTime',
            key: 'endTime',
        }
    ];

    const handleChangeStatus = ({ meta, file }:any,  status:any) => {
        console.log(status, meta, file)
        setFileState(status)
    }

    const handleSubmitTR = (files: any) => {
        console.log(files.map((f:any) => f.meta));
      };

    const handleSubmit = async (files:any, allFiles:any) => {
        console.log(files.map((f: any) => f.meta))
        const f = files[0];
        const fileName = f.file.name
        console.log("json")
        console.log(JSON.stringify({ fileName }))
        setloading(true)
        try {
          const response = await fetch(sbDataMappingUploadfileUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify({ fileName }),
          });

          if (!response.ok) {
            throw new Error(`Error! status: ${response.status}`);

          }

          const result = await response.json();
          console.info('result to get presigned url is: ', JSON.stringify(result, null, 4));
          uploadurl_link = result.uploadUrl
          objectid_store = result.objectId
          console.log(result.uploadUrl)

          const submitFile = await fetch(uploadurl_link, {
            method: "PUT",
            body: f["file"],
          });
          setFileState('uploaded')
          allFiles.forEach((f:any) => f.remove())
          await new Promise(r => setTimeout(r, 2000));  // workaround to wait for entry
          alert("Mapping uploaded successfully! Please wait a few seconds for the table to refresh.")
          await fetchData()
          // PUT request: upload file to S3
          // sendtoDB()
          // alert("qr code succesfully uploaded!");

        } catch (err) {
          setErr((err as Error).message);
        }
      }

      const fetchData = async () => {
        const response = await fetch(sbDataMappingStatusUrl);
        const result = await response.json();
        console.info("data fetched from jobs ddb", result);
        setData(result); // Assuming the API returns the array directly
        setloading(false)
    };

      const refreshData = () => {
        console.log("Refreshing data...");
        fetchData(); // Re-fetch the data when the button is clicked
     };


    return (
        <>
            <Row>
              <Col span={8}>
                <Title level={3}>Mobifone Mapping Upload</Title>
              </Col>
            </Row>
            <Dropzone
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                maxFiles={1}
                multiple={false}
                canCancel={true}
                inputContent="Drag file here or browse"
                styles={{
                    dropzone: { width: 800, height: 200 },
                    dropzoneActive: { borderColor: "black" },
                }}
            />
            <div style={{ marginBottom: 5, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                icon={<ReloadOutlined />}
                onClick={refreshData}
                style={{ marginBottom: 5 }}
            >
                Refresh
            </Button>

            </div>
            <Table
                dataSource={data}
                columns={columns}
                loading={{ indicator: <div><Spin /></div>, spinning:loading}}
                size="small" />
        </>
    );

}
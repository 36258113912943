export function formatDateISO(dateStr: string): string {
  if (!dateStr) {
    return "";
  }
  const date = new Date(dateStr); // Directly parse ISO date string

  // Array of month names for formatting
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const day = date.getDate(); // Get the day as a number
  const monthIndex = date.getMonth(); // Get the month index (0-based)
  const year = date.getFullYear(); // Get the full year

  const formattedDay = day.toString().padStart(2, '0'); // Ensures two digits for day
  const formattedMonth = months[monthIndex];
  const formattedYear = year.toString();

  // Return formatted date in DD MMM YYYY format
  return `${formattedDay} ${formattedMonth} ${formattedYear}`; // Formatted as "11 May 2024"
}


/**
 * Accepts ISO UTC datetime string
 */
export function formatDateTimeISO(datetimeString: string): string {
  let formattedDtStr = datetimeString;
  if (!formattedDtStr.endsWith("Z")) {
    formattedDtStr += "Z";
  }
  const date = new Date(formattedDtStr);

  const pad = (num: number) => num.toString().padStart(2, '0');

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are 0-based
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

import { emptyApi as api } from "./emptyApi";
import { Availability } from "./slowboatApi";

const injectedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVnAvailability: build.query<Availability[], void>({
      query: () => "/vn-data-mapping/inventory/availability",
      // providesTags: ["Availability"] // Uncomment when ready for caching/tagging
    }),
  }),
});
export { injectedApi as slowboatApi };

export const {
  useGetVnAvailabilityQuery
} = injectedApi;
import { ThemeConfig } from "antd";

const theme: ThemeConfig = {
  token: {
    colorPrimary: "rgb(255, 70, 37)",  // Mailersend colour
    colorLink: "rgb(255, 70, 37)",
    colorInfo: "rgb(255, 70, 37)",
    fontFamily: "BioSans Regular"
  },
  components: {
    Layout: {
      headerBg: "#ffffff",
      bodyBg: "rgb(246,247,249)",
      // footerBg: "#ffffff",
      footerBg: "rgb(246,247,249)",
    },
    Menu: {
      // subMenuItemBg: "rgba(0, 0, 0, 0.01)"
      subMenuItemBg: "rgb(252, 252, 252)",
      itemSelectedBg: "rgba(0,0,0,0.05)",
    },
  },
}

export default theme;
/**
 * Required schema from API Gateway URL:
 *
 * Get an upload URL to upload an attachment
 *  {
 *      "filename": "myfile.jpg"
 *  }
 *  Returns:
 *  {
 *      "objectId": "objectId",
 *      "uploadUrl": "uploadUrl"  // Presigned URL for S3 upload
 *  }
 *
 * @param url An API Gateway URL endpoint that generates presigned S3 URL.
 * @param f File to be uploaded
 * @returns Object ID of the uploaded file, or null if failed
 */
export default async function handleUploadFile(url: string, f: File) {
  const filename = f.name
  const resGenUrl = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ filename }),
  });

  if (!resGenUrl.ok) {
    return null;
  }

  const result = await resGenUrl.json();
  const resUpload = await fetch(result.uploadUrl, {
    method: "PUT",
    body: f,
  });
  if (!resUpload.ok) {
    return null;
  }
  return result.objectId;
}
import React from "react";
import { Button, Image, List, Typography } from "antd";

const { Title } = Typography;

type Props = {
  attachments: string[];
};

export const getFileName = (url: string) => {
  const parts = url.split("/");
  return parts[parts.length - 1];
};

const AttachmentsSection = ({ attachments }: Props) => {
  // Separate images and non-image files
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  const images = attachments.filter((file) =>
    imageExtensions.some((ext) => file.toLowerCase().endsWith(ext))
  );
  const otherFiles = attachments.filter(
    (file) => !imageExtensions.some((ext) => file.toLowerCase().endsWith(ext))
  );

  function handleDownload(url: string) {
    window.open(url, "_blank");
  }

  return (
    <div style={{ marginTop: "20px" }}>
      {/* Image Gallery */}
      {images.length > 0 && (
        <div style={{ marginBottom: "20px" }}>
          <Title level={5}>Images</Title>
          <Image.PreviewGroup>
            {images.map((image, index) => (
              <Image
                key={index}
                src={image}
                alt={`Image ${index + 1}`}
                width={200}
                style={{ margin: "10px" }}
              />
            ))}
          </Image.PreviewGroup>
        </div>
      )}

      {/* File List */}
      {otherFiles.length > 0 && (
        <div>
          <Title level={5}>Attachments</Title>
          <List
            bordered
            dataSource={otherFiles}
            renderItem={(file) => (
              <List.Item>
                <Typography.Text>{ getFileName(file) }</Typography.Text>
                <Button type="default" onClick={() => handleDownload(file)}>
                  Download
                </Button>
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default AttachmentsSection;
import {
  Button,
  Col,
  Flex,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Table,
  Tabs,
  Typography,
} from "antd";
import { KycData, SimCard } from ".";
import Container from "../Container";
import { formatDateTimeISO } from "../../../utils/date";
import { useEffect, useState } from "react";
import { MailOutlined } from "@ant-design/icons";
import axios from "axios";
import { validateEmail } from "../../../utils/emailValidation";
import { Auth } from 'aws-amplify';

const { Text } = Typography;

const RESEND_EMAIL_API_PROD_URL = "https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/vn-data-mapping/resend-email";
const RESEND_EMAIL_API_DEV_URL = RESEND_EMAIL_API_PROD_URL;

const RESEND_EMAIL_API_URL = process.env.NODE_ENV === "production" || process.env.VERCEL_ENV === "production" ? RESEND_EMAIL_API_PROD_URL : RESEND_EMAIL_API_DEV_URL;

const REDEMPTIONS_ADMIN_API_PROD_URL = "https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/sb-redemption/admin/redeem";
const REDEMPTIONS_ADMIN_API_DEV_URL = REDEMPTIONS_ADMIN_API_PROD_URL;
const REDEMPTIONS_ADMIN_API_URL = process.env.NODE_ENV === "production" || process.env.VERCEL_ENV === "production" ? REDEMPTIONS_ADMIN_API_PROD_URL : REDEMPTIONS_ADMIN_API_DEV_URL;

interface InventoryModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  sim: SimCard | null; // to be updated
  kycData: KycData | null; // to be updated
  onSubmitKycData: () => void;
  tab: number | null | undefined;
}

function getFriendlyKycStatus(kycData: KycData | null) {
  if (kycData === null) {
    return "Unavailable";
  }

  if (kycData.status === "PENDING") {
    return "Pending Redemption";
  } else if (kycData.status === "REDEEMED"
      && kycData.kycSubmittedBy === null) {
    return "Awaiting KYC Submission";
  } else if (kycData.status === "REDEEMED"
      && kycData.kycSubmittedBy !== null) {
    return "Submitted";
  } else {
    return "Unknown";
  }
}

export default function InventoryModal(props: InventoryModalProps) {
  const [messageApi, contextHolder] = message.useMessage();
  const [emailToResend, setEmailToResend] = useState<string>("");

  useEffect(() => {
    if (props.sim !== null) {
      setEmailToResend(props.sim.customerEmail);
    } else {
      setEmailToResend("");
    }
  }, [props.sim]);

  function handleOk() {
    // TODO: Save
    props.setIsModalVisible(false);
  }

  function handleCancel() {
    props.setIsModalVisible(false);
  }

  async function handleResendEmail() {
    console.log("handleResendEmail");
    if (emailToResend === "") {
      messageApi.error("Email address cannot be empty.");
      return;
    }

    if (!validateEmail(emailToResend)) {
      messageApi.error("Invalid email address.");
      return;
    }

    messageApi.open({
      type: "loading",
      content: "Resending email...",
      duration: 0,
    });
    try {
      await axios.post(RESEND_EMAIL_API_URL, {
        snNo: props.sim!!.iccid,
        email: emailToResend,
      });
      messageApi.destroy();
      messageApi.success("Email sent!");
    } catch (ex) {
      messageApi.destroy();
      messageApi.error("Failed to resend email.");
    }
  }

  async function handleSubmitKyc() {
    console.log("handleSubmitKyc");
    let uname = "";
    const fetchUser = async () => {
      let session = await Auth.currentUserInfo();
      console.log('User info Session', session.username)
      uname = session.username;
    }
    await fetchUser();
    const postBody = {
      orderId: props.kycData!!.orderId,
      simIdx: props.kycData!!.simIdx,
      empName: uname
    }
    try {
      const res = await axios.post(REDEMPTIONS_ADMIN_API_URL, postBody);
      if (res.status === 200) {
        messageApi.success("KYC submitted successfully.");
        props.onSubmitKycData();
        props.setIsModalVisible(false);
      } else {
        messageApi.error(res.data.message);
      }
    } catch (ex) {
      messageApi.error("Failed to submit KYC.");
    }
  }

  return (<>
    {contextHolder}
    <Modal
      className="order-modal"
      width="80%"
      title="eSIM Details"
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.sim === null || props.sim === undefined ? (
        <Spin />
      ) : (
        <Tabs
          type="card"
          defaultActiveKey={props.tab?.toString() ?? "1"}
          items={[
            {
              label: "Order Detail",
              key: "1",
              children: (
                <Container>
                  <Text type="secondary">Order Information</Text>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Input
                        readOnly
                        addonBefore="Customer Email"
                        value={props.sim.customerEmail}
                      />
                    </Col>
                    <Col span={8}>
                      <Input
                        readOnly
                        addonBefore="Order Processed Date"
                        value={props.sim.orderProcessedDate}
                      />
                    </Col>
                    <Col span={8}>
                      <Input
                        readOnly
                        addonBefore="System Status"
                        value={props.sim.status === ""
                              ? "Available"
                              // temporary workaround until db is updated
                              : props.sim.status === "FULFILLED"
                              ? "Used"
                              : props.sim.status}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Input
                        readOnly
                        addonBefore="Order Submitted By"
                        value={props.sim.empName ?? "-"}
                      />
                    </Col>
                  </Row>

                  <Text type="secondary">SIM Card</Text>

                  <Row gutter={16}>
                    <Col span={12}>
                      <Input
                        readOnly
                        addonBefore="Imported On"
                        value={formatDateTimeISO(props.sim.dateTime)}
                      />
                    </Col>
                    <Col span={12}>
                      <Input
                        readOnly
                        addonBefore="Expiry Date"
                        value={props.sim.expiryDate ?? "Unavailable"}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={24}>
                      <Input addonBefore="Item Name" value={props.sim.name} />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={24}>
                      <Input
                        addonBefore="Activation Code URL"
                        value={props.sim.activationCodeUri}
                      />
                    </Col>
                  </Row>

                </Container>
              ),
            },
            {
              label: "Redemption Information",
              key: "2",
              disabled: props.sim.status === "",
              children: (
                <Container>
                  <Row gutter={8}>
                    <Col span={props.kycData?.kycSubmittedBy === null ? 20 : 12}>
                      <Input
                        readOnly
                        addonBefore="Status"
                        value={getFriendlyKycStatus(props.kycData)}
                      />
                    </Col>
                    <Col span={props.kycData?.kycSubmittedBy == null ? 0 : 12}>
                      <Input
                        readOnly
                        addonBefore="Submitted By"
                        value={props.kycData?.kycSubmittedBy}
                      />
                    </Col>
                    { props.kycData?.kycSubmittedBy === null && (
                      <Col span={4}>
                        <Button
                          type="primary"
                          onClick={handleSubmitKyc}
                        >
                          Mark as Submitted
                        </Button>

                      </Col>
                    )}
                  </Row>
                  <Text type="secondary">Redemption Information</Text>
                  <Row gutter={8}>
                    <Col span={24}>
                      <Input
                        readOnly
                        addonBefore="Platform SKU"
                        value={props.kycData?.platformId ?? "Unavailable"}
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={24}>
                      <Input
                        readOnly
                        addonBefore="Arrival Date in Vietnam"
                        value={props.kycData?.arrivalDate}
                      />
                    </Col>
                  </Row>
                </Container>
              )
            },
            {
              label: "Resend Email",
              key: "3",
              disabled: props.sim.status === "",
              children: (
                <Container>
                  <Row justify="space-between" gutter={8}>
                    <Col flex="auto">
                      <Input
                        addonBefore="Email Address"
                        type="email"
                        value={emailToResend}
                        onChange={(e) => setEmailToResend(e.target.value.trim())}
                      />
                    </Col>
                    <Col>
                      <Button
                        onClick={handleResendEmail}
                        icon={<MailOutlined />}
                      >
                        Send
                      </Button>
                    </Col>
                  </Row>
                </Container>
              ),
            },
            {
              label: "Comments",
              key: "4",
              disabled: props.sim.status === "",
              children: (
                <>
                  <Input.TextArea
                    value={props.sim.comments}
                    readOnly
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </>
              ),
            },
          ]}
        />
      )}
    </Modal>
  </>);
}

import { Button, Card, Flex, Spin, Table, TableProps, Typography } from "antd";
import { Redemption, useGetRedemptionsAllVendorsQuery, useGetRedemptionsQuery } from "../../../api/slowboatApi";
import { useNavigate } from "react-router-dom";

/**
 * KYC Pending Submission Card
 */
export default function PendingSubmissionCard() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetRedemptionsAllVendorsQuery();

  const filteredData =
    data?.filter(
      // () => true
      (order) => order.status === "REDEEMED" && order.kycSubmittedBy === null
    ) ?? [];

  const columns: TableProps<Redemption>["columns"] = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "KYC Status",
      dataIndex: "kycSubmittedBy",
      key: "kycSubmittedBy",
      render: (user: string) => (user ? "Submitted" : "Pending"),
    },
    {
      title: "Action",
      render: (_, record: Redemption) => (
        <>
          <Button
            type="primary"
            disabled={record.assignedSimSn === null}
            onClick={() => {
                if (record.vendorId === "auvodafone") {
                  return navigate(`/sb-inventory?simSn=${record.assignedSimSn}&action=kycData`)
                } else {
                  return navigate(`/vn-inventory?simSn=${record.assignedSimSn}&action=kycData`)
                }
              }
            }
          >
            View
          </Button>
        </>
      ),
    },
  ];

  return (
    <Card
      title="Pending Submission"
      style={{ width: "100%", height: "100%" }}
    >
      {isLoading && (
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Spin />
        </Flex>
      )}
      {error && (
        <Typography.Text>Error loading inventory status</Typography.Text>
      )}
      {data && (
        <Table<Redemption>
          columns={columns}
          dataSource={filteredData.slice(0, 5)}
          rowKey="id"
          pagination={false}
          size="small"
        />
      )}
    </Card>
  );
}

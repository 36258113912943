import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const BASE_URL = "https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod"

/**
 * Empty API for code generation with @rtk-query/codegen-openapi
 */
export const emptyApi = createApi({
  reducerPath: "simplyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    timeout: 10000
  }),
  endpoints: () => ({})
});

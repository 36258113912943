import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link, Routes, useNavigate } from "react-router-dom";


import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingTwoTone,
  HomeTwoTone,
  UsergroupAddOutlined,
  EnvironmentTwoTone,
  ToolTwoTone,
  ShopTwoTone,
  ArrowRightOutlined
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Space,
  Checkbox,
  Form,
  Input,
  Grid
} from "antd";
import { Auth } from 'aws-amplify';

const { useBreakpoint } = Grid;

//// Menu Props for ant.dd
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
    // Check if the label is a string and wrap it with a <Link>
    let wrappedLabel = label;

      // Convert key to string for use in the Link component
      const keyStr = key.toString();

    // Check if the label is a string and does not start with "sub"
    if (typeof label === 'string' && !keyStr.startsWith("/sub")) {
      wrappedLabel = <Link to={key.toString()}>{label}</Link>;
    }

  return {
    key,
    icon,
    children,
    label: wrappedLabel,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Home", "/home-admin", <HomeTwoTone />, [
    getItem("Announcements", "/announcements"),
  ]),
  getItem('eSIM', '/sub1', <EnvironmentTwoTone />, [

      {
        key: 'sub1-2',
        label: 'Joytel',
        icon: <ArrowRightOutlined />,
        children: [
          getItem("Order Submit", "/jy-submit"),
          getItem("Order History", "/jy-history"),
          getItem("Redemption History", "/rd-history"),
          getItem("Manual Callback", "/manual-cb"),
          getItem("Update Mapping", "/u-mapping"),
        ],
      },
      {
        key: 'sub1-3',
        label: 'EliteMobile',
        icon: <ArrowRightOutlined />,
        children: [
          getItem("SIM Topup", "/em-recharge"),
          getItem("Top Up Report", "/em-recharge-history")
        ]
      },
      {
        key: 'sub1-4',
        label: 'Slowboat',
        icon: <ArrowRightOutlined />,
        children: [
          getItem("Order Submit", "/sb-submit"),
          getItem("Update Mapping", "/sb-mapping"),
          getItem("Inventory", "/sb-inventory"),
          getItem("Redemptions", "/sb-redemptions"),
        ]
      },
      {
        key: 'sub1-5',
        label: 'Mobifone',
        icon: <ArrowRightOutlined />,
        children: [
          getItem("Order Submit", "/vn-submit"),
          getItem("Update Mapping", "/vn-mapping"),
          getItem("Inventory", "/vn-inventory"),
          getItem("Redemptions", "/vn-redemptions"),
        ]
      }
      // {
      //   key: 'sub1-3',
      //   label: 'TGT',
      //   children: [
      //     // getItem("Order Submit", "/jy-submit"),
      //     // getItem("Order History", "/jy-history"),
      //     // getItem("Redemption History", "/rd-history"),
      //     // getItem("Manual Callback", "/manual-cb"),
      //   ],
      // },
      // {
      //   key: 'sub1-4',
      //   label: 'Simply',
      //   children: [
      //     // getItem("Order Submit", "/jy-submit"),
      //     // getItem("Order History", "/jy-history"),
      //     // getItem("Redemption History", "/rd-history"),
      //     // getItem("Manual Callback", "/manual-cb"),
      //   ],
      // },
  ]),
  getItem('Platforms', '/sub3', <ShopTwoTone />, [
    getItem("Shopee Orders", "/shopee-view-orders"),
  ]),
  getItem('User Settings', '/sub4', <ToolTwoTone />, [
    getItem("Add New User", "/u-add"),
    getItem("Activity Log", "/u-history"),
  ]),
  getItem('Admin Settings', '/sub5', <SettingTwoTone />, [
    getItem('Order Query', '/testapi', <AppstoreOutlined />),
    getItem("Joytel Test", "/jy-submit-test", <AppstoreOutlined />),
  ]),


]

const itemsUser: MenuItem[] = [
  getItem("Home", "/", <HomeTwoTone />, [
    getItem("Announcements", "/announcements"),
  ]),
  getItem('eSIM', '/sub1', <EnvironmentTwoTone />, [

      {
        key: 'sub1-2',
        label: 'Joytel',
        icon: <ArrowRightOutlined />,
        children: [
          getItem("Order Submit", "/jy-submit"),
          getItem("Order History", "/jy-history"),
          getItem("Redemption History", "/rd-history"),
          getItem("Manual Callback", "/manual-cb"),
          getItem("Update Mapping", "/u-mapping"),
        ],
      },
      {
        key: 'sub1-4',
        label: 'Slowboat',
        icon: <ArrowRightOutlined />,
        children: [
          getItem("Order Submit", "/sb-submit"),
          getItem("Update Mapping", "/sb-mapping"),
          getItem("Inventory", "/sb-inventory"),
          getItem("Redemptions", "/sb-redemptions"),
        ]
      },
      {
        key: 'sub1-5',
        label: 'Mobifone',
        icon: <ArrowRightOutlined />,
        children: [
          getItem("Order Submit", "/vn-submit"),
          getItem("Update Mapping", "/vn-mapping"),
          getItem("Inventory", "/vn-inventory"),
          getItem("Redemptions", "/vn-redemptions"),
        ]
      },
      {
        key: 'sub1-3',
        label: 'EliteMobile',
        icon: <ArrowRightOutlined />,
        children: [
          getItem("SIM Topup", "/em-recharge"),
          getItem("Top Up Report", "/em-recharge-history")
        ]
      },
      // {
      //   key: 'sub1-3',
      //   label: 'TGT',
      //   children: [
      //     // getItem("Order Submit", "/jy-submit"),
      //     // getItem("Order History", "/jy-history"),
      //     // getItem("Redemption History", "/rd-history"),
      //     // getItem("Manual Callback", "/manual-cb"),
      //   ],
      // },
      // {
      //   key: 'sub1-4',
      //   label: 'Simply',
      //   children: [
      //     // getItem("Order Submit", "/jy-submit"),
      //     // getItem("Order History", "/jy-history"),
      //     // getItem("Redemption History", "/rd-history"),
      //     // getItem("Manual Callback", "/manual-cb"),
      //   ],
      // },
  ]),
  getItem('User Settings', '/sub3', <ToolTwoTone />, [
    getItem("Add New User", "/u-add"),
    getItem("Activity Log", "/u-history"),
  ]),

]

// submenu keys of first level
const rootSubmenuKeys = ["sub1", "sub2"];

export default function SideBar() {

    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [collapsed, setCollapsed] = useState(false);
    const [uname, setuname] = useState("")
    const [uprofile, setuprofile] = useState("")
    const [ditems, setditems] = useState<MenuItem[]>([]);
    const screens = useBreakpoint();

    const fetchData = async () => {
       let session = await Auth.currentUserInfo();
       console.log('User info Session', session.attributes.profile)
       setuname(session.username)
       setuprofile(session.attributes.profile)
       if (session.attributes.profile === 'user') {
        setditems(itemsUser);
      } else if (session.attributes.profile === 'admin') {
        setditems(items);
      } else {
        setditems(itemsUser);
      }
    }

    useEffect(() => {
     fetchData()
      }, []);

    const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
      const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
      if (rootSubmenuKeys.includes(latestOpenKey!)) {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      } else {
        setOpenKeys(keys);
      }
    };

    const toggleCollapsed = () => {
      setCollapsed(!collapsed);
    };

    return (
      <>

      <Menu
        mode="inline"
        defaultSelectedKeys={[]}
        defaultOpenKeys={[]}
        onOpenChange={onOpenChange}
        style={{
          height: "100%",
          borderRight: 0,
          whiteSpace: "normal",
          // position: "fixed",
          // width: "auto",
          // left: 0,
          // zIndex: 999,
          // top: 0,
          // paddingTop: 16,
          // maxHeight: "100vh",
        }}
        inlineCollapsed={screens.md ? false : true} // Collapse on small screens
        items={ditems}
      />
    </>
    )

}
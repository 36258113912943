import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Tabs,
  Typography,
} from "antd";
import { RedemptionGroupedData } from ".";
import Container from "../Container";
import { useState } from "react";
import Table, { ColumnsType } from "antd/es/table";
import { formatDateTimeISO } from "../../../utils/date";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

interface RedemptionRow {
  simIdx: number;
  assignedSimSn: string | null;
  status: string;
  redemptionDate: string | null;
  platformId: string | undefined;
}

console.log("environment", process.env.NODE_ENV, process.env.VERCEL_ENV);

interface InventoryModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;

  data: RedemptionGroupedData | null;
}

export default function RedemptionDetailsModal(props: InventoryModalProps) {
  const navigate = useNavigate();

  function handleOk() {
    // TODO: Save
    props.setIsModalVisible(false);
  }

  function handleCancel() {
    props.setIsModalVisible(false);
  }

  function handleViewKycData(record: RedemptionRow) {
    // Coming soon
    navigate(`/vn-inventory?simSn=${record.assignedSimSn}&action=kycData`);
  }

  const columns: ColumnsType<RedemptionRow> = [
    {
      title: "#",
      dataIndex: "simIdx",
      width: 5,
      key: "simIdx",
      sorter: (a, b) => a.simIdx - b.simIdx,
    },
    {
      title: "Platform SKU",
      dataIndex: "platformId",
      key: "platformId",
      sorter: (a, b) => a.platformId?.localeCompare(b.platformId !== undefined ? b.platformId : "-") ?? -1,
      render: (value: string) => value === undefined ? "-" : value
    },
    {
      title: "Assigned SIM SN",
      dataIndex: "assignedSimSn",
      key: "assignedSimSn",
      sorter: (a, b) => a.assignedSimSn?.localeCompare(b.assignedSimSn!!) ?? -1,
      render: (value: string) => value === null ? "-" : value,
    },
    {
      title: "Redemption Date",
      dataIndex: "redemptionDate",
      key: "redemptionDate",
      sorter: (a, b) => a.redemptionDate?.localeCompare(b.redemptionDate!!) ?? -1,
      render: (value: string) => value === null ? "-" : formatDateTimeISO(value),
    },
    {
      title: "Action",
      key: "operation",
      width: 5,
      render: (text, record) => (
        <>
          <Button
            type="primary"
            disabled={record.assignedSimSn === null}
            onClick={() => handleViewKycData(record)}
          >
            Go to KYC Data
          </Button>
        </>
      ),
    },
  ];

  const simData: RedemptionRow[] = props.data?.kycs.map((kyc, idx): RedemptionRow => ({
    simIdx: kyc.simIdx,
    assignedSimSn: kyc.assignedSimSn ?? null,
    status: kyc.status,
    platformId: kyc.platformId ?? undefined,
    redemptionDate: kyc.assignedSimSn ? kyc.updatedAt : null
  })) ?? [];

  return (<>
    <Modal
      className="order-modal"
      width="80%"
      title="Redemption Details"
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
        <Tabs
          type="card"
          defaultActiveKey="1"
          items={[
            {
              label: "Redemption Information",
              key: "1",
              children: (
                <Container>
                  <Text type="secondary">Order Information</Text>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Input
                        readOnly
                        addonBefore="Order Date"
                        value={props.data ? formatDateTimeISO(props.data.orderDate) : "-"}
                      />
                    </Col>
                    <Col span={12}>
                      <Input
                        readOnly
                        addonBefore="Order ID"
                        value={props.data?.orderId}
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Input
                        readOnly
                        addonBefore="Status"
                        value={props.data?.status + " redeemed"}
                      />
                    </Col>
                    <Col span={12}>
                      <Input
                        readOnly
                        addonBefore="Order Email"
                        value={props.data?.orderEmail ?? "Unavailable"}
                      />
                    </Col>
                  </Row>
                  <Text type="secondary">SIMs</Text>
                  <Table
                    columns={columns}
                    dataSource={simData}
                    pagination={{
                      defaultPageSize: 5,
                      pageSizeOptions: [5, 10, 15, 20],
                      defaultCurrent: 1
                    }}
                    size="small"
                    bordered
                    showHeader
                  />
                </Container>
              ),
            },
          ]}
        />
    </Modal>
  </>);
}

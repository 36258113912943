import { Card, Flex, Spin, Table, TableProps, Typography } from "antd";
import { Availability, useGetAvailabilityQuery } from "../../../api/slowboatApi";
import { useGetVnAvailabilityQuery } from "../../../api/vnApi";

const columns: TableProps<Availability>["columns"] = [
  {
    title: "SKU",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Quantity",
    dataIndex: "count",
    key: "count",
  }
]

export default function InventoryCard() {
  const { data: slowboatData, error: slowboatError, isLoading: slowboatIsLoading } = useGetAvailabilityQuery();
  const { data: vnData, error: vnError, isLoading: vnIsLoading } = useGetVnAvailabilityQuery();

  let data = [];
  if (slowboatData) {
    data.push(...slowboatData);
  }
  if (vnData) {
    data.push(...vnData);
  }
  const error = slowboatError || vnError;
  const isLoading = slowboatIsLoading || vnIsLoading;

  return (
    <Card title="Inventory" style={{ width: "100%", height: "100%" }}>
      { isLoading &&
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Spin />
        </Flex>
      }
      { error && <Typography.Text>Error loading inventory status</Typography.Text> }
      { data && !isLoading &&
        <Table<Availability>
          columns={columns}
          dataSource={data.slice(0, 5)}
          rowKey="id"
          pagination={false}
          size="small"
        />
      }
    </Card>
  )
}
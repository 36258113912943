import { emptyApi as api } from "./emptyApi";
const injectedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAnnouncements: build.query<Announcement[], void>({
      query: () => "/portal/announcement",
      // providesTags: ["Announcement"]  // Uncomment when migrated fully over for caching
    })
  })
});

/**
 * Types
 * TODO: Migrate to autogenerated API slices
 */
export type Announcement = {
  content: string;
  readBy: string[];
  updatedAt: string;
  createdAt: string;
  id: string;
  author: string;
  title: string;
  attachments: string[];
}

export { injectedApi as announcementsApi };

export const {
  useGetAnnouncementsQuery
} = injectedApi;
import { Col, Row, Input, Typography, List, Flex, message, Button, Modal } from "antd";
import { useEffect, useState } from "react";
import Content from "./components/Content";
import useFetchUsername from "../../hooks/useFetchUsername";
import { ExclamationCircleFilled, ExclamationCircleOutlined, ExclamationCircleTwoTone, ExclamationOutlined, PlusOutlined } from "@ant-design/icons";
import CreateAnnouncementModal from "./components/CreateAnnouncementModal";
import theme from "../../theme";
import checkIfUnread from "./util/checkIfUnread";
import useQuery from "../../utils/useQuery";

const { Search } = Input;
const { Text } = Typography;

const PROD_ENDPOINT = "https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/portal/announcement";
const DEV_ENDPOINT = PROD_ENDPOINT;
// const DEV_ENDPOINT = "https://wv90e9zk2m.execute-api.ap-southeast-1.amazonaws.com/test/portal/announcement";
const PROD_ACK_ENDPOINT = "https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/portal/announcement/acknowledge";
// const DEV_ACK_ENDPOINT = "https://wv90e9zk2m.execute-api.ap-southeast-1.amazonaws.com/test/portal/announcement/acknowledge";
const DEV_ACK_ENDPOINT = PROD_ACK_ENDPOINT;

const PROD_AUTH_ENDPOINT = "https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/portal/announcement/authorized";
const DEV_AUTH_ENDPOINT = PROD_AUTH_ENDPOINT;

const PROD_UPLOAD_ENDPOINT = "https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/portal/announcement/upload";
const DEV_UPLOAD_ENDPOINT = PROD_UPLOAD_ENDPOINT;

export const ENDPOINT = (process.env.NODE_ENV === "production" || process.env.VERCEL_ENV === "production") ? PROD_ENDPOINT : DEV_ENDPOINT;
export const ACK_ENDPOINT = (process.env.NODE_ENV === "production" || process.env.VERCEL_ENV === "production") ? PROD_ACK_ENDPOINT : DEV_ACK_ENDPOINT;
export const AUTH_ENDPOINT = (process.env.NODE_ENV === "production" || process.env.VERCEL_ENV === "production") ? PROD_AUTH_ENDPOINT : DEV_AUTH_ENDPOINT;
export const ATTACHMENT_UPLOAD_ENDPOINT = (process.env.NODE_ENV === "production" || process.env.VERCEL_ENV === "production") ? PROD_UPLOAD_ENDPOINT : DEV_UPLOAD_ENDPOINT;

export const ATTACHMENT_S3_BASE_URL = "https://portal-announcements-attachments.s3.ap-southeast-1.amazonaws.com/"  // + objectId

export type Announcement = {
  content: string;
  readBy: string[];
  updatedAt: string;
  createdAt: string;
  id: string;
  author: string;
  title: string;
  attachments: string[];
}

function Header({ onAdd, isAnnouncer }: { onAdd: () => void, isAnnouncer: boolean }) {
  return (
    <Flex justify="space-between">
      <Typography.Title level={5} style={{ margin: "4px" }}>Announcements</Typography.Title>
      { isAnnouncer && <Button icon={<PlusOutlined />} onClick={onAdd} /> }
    </Flex>
  )
}

export default function Announcements() {
  const uname = useFetchUsername();
  const [selected, setSelected] = useState<Announcement | null>(null);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredAnns, setFilteredAnns] = useState<Announcement[]>([]);
  const [searchText, setSearchText] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isAuthorizedAnnouncer, setIsAuthorizedAnnouncer] = useState(false);

  const [isPreloaded, setIsPreloaded] = useState(false);
  const query = useQuery();

  useEffect(() => {
    const pId = query.get("id");
    if (isLoading || isPreloaded) {
      return;
    }

    if (pId !== null) {
      const announcement = announcements.find(ann => ann.id === pId);
      if (announcement) {
        setSelected(announcement);
      }
      setIsPreloaded(true);
    }
  }, [query, isLoading, isPreloaded, announcements]);

  useEffect(() => {
    if (!uname) {
      return;
    }

    fetchAuthorizedAnnouncer();
    fetchAnnouncements();
  }, [uname]);

  useEffect(() => {
    const sorted = announcements.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
    const filtered = sorted.filter(ann => ann.title.toLowerCase().includes(searchText.toLowerCase()));
    setFilteredAnns(filtered);
  }, [announcements, searchText]);

  async function fetchAuthorizedAnnouncer() {
    const res = await fetch(AUTH_ENDPOINT);
    const data = await res.json();
    if (data.includes(uname)) {
      setIsAuthorizedAnnouncer(true);
    } else {
      setIsAuthorizedAnnouncer(false);
    }
  }

  async function fetchAnnouncements() {
    setIsLoading(true);
    const res = await fetch(ENDPOINT);
    const data: Announcement[] = await res.json();
    setAnnouncements(data);
    if (data.length === 0) {
      setSelected(null);
    } else {
      const latestAnnouncement = data.sort((a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )[0];
      setSelected(latestAnnouncement);
    }
    console.log("data", data)
    setIsLoading(false);
  }

  function handleSelectItem(item: Announcement) {
    setSelected(item);
  }

  async function handleAcknowledge() {
    const res = await fetch(ACK_ENDPOINT, {
      method: "PUT",
      body: JSON.stringify({
        id: selected!!.id,
        user: uname
      })
    });
    if (res.status === 200) {
      messageApi.success("Acknowledged announcement")
      fetchAnnouncements();
    }
  }

  function handleDelete() {
    Modal.confirm({
      title: "Delete announcement",
      content: "Are you sure you want to delete this announcement?",
      onOk: async () => {
        const res = await fetch(`${ENDPOINT}?id=${selected!!.id}`, {
          method: "DELETE"
        });
        if (res.status === 200) {
          messageApi.success("Deleted announcement");
          fetchAnnouncements();
        } else {
          messageApi.error("Failed to delete announcement");
        }
      }
    });
  }

  function handleCreateAnnouncement() {
    setIsCreating(true);
  }

  function handleOnCreated() {
    fetchAnnouncements();
  }

  return (<>
    {contextHolder}
    <Row gutter={16} style={{ height: "100%" }}>
      <Col span={8} style={{ display: "flex", flex: 1 }}>
        <Flex vertical flex={1} gap={8}>
          <Search
            placeholder="Search announcements"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          <List
            header={<Header onAdd={handleCreateAnnouncement} isAnnouncer={isAuthorizedAnnouncer} />}
            bordered
            split={false}
            dataSource={filteredAnns}
            size="small"
            renderItem={(item: Announcement) => (
              <List.Item>
                <Button
                  block
                  icon={checkIfUnread(item, uname) ? <ExclamationCircleTwoTone /> : undefined}
                  type="text"
                  onClick={() => handleSelectItem(item)}
                  style={{ textAlign: "left" }}
                >
                  {item.title}
                </Button>
              </List.Item>
            )}
            style={{ flex: 1, overflow: "auto", backgroundColor: "white" }}
          />
        </Flex>
      </Col>
      <Col span={16}>
        { selected ? (
          <Content
            announcement={selected}
            onAcknowledge={handleAcknowledge}
            onTriggerRefresh={fetchAnnouncements}
            onDelete={handleDelete}
            isAnnouncer={isAuthorizedAnnouncer}
          />
        ) : <Text>Select an announcement on the left</Text>}
      </Col>
    </Row>
    <CreateAnnouncementModal
      visible={isCreating}
      onCreated={() => handleOnCreated()}
      onDismiss={() => setIsCreating(false)}
    />
  </>)
}
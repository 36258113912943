import { emptyApi as api } from "./emptyApi";

const injectedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRedemptions: build.query<Redemption[], void>({
      query: () => "/sb-redemption/admin/redeem"
    }),
    getRedemptionsAllVendors: build.query<Redemption[], void>({
      query: () => "/sb-redemption/admin/redeem?vendor_id=ALLVENDOR"
    }),
    getAvailability: build.query<Availability[], void>({
      query: () => "/sb-data-mapping/inventory/availability",
      // providesTags: ["Availability"] // Uncomment when ready for caching/tagging
    }),
  }),
});

/**
 * Types
 * This corresponds to the array of objects like:
 * [
 *   {
 *     "title": "Vodafone eSIM",
 *     "count": 56
 *   }
 * ]
 */
export type Availability = {
  title: string;
  count: number;
};

export type Redemption = {
  orderId: string;
  status: string;
  createdAt: string;
  passportNumber: string;
  vendorId: string;
  email: string;
  assignedSimSn: string;
  surname: string;
  middleName?: string;
  kycSubmittedBy: string;
  updatedAt: string;
  dob: string;
  passportCountry: string;
  simIdx: number;
  arrivalDate: string;
  givenName: string;
  productId: string;
}

export { injectedApi as slowboatApi };

export const {
  useGetAvailabilityQuery,
  useGetRedemptionsQuery,
  useGetRedemptionsAllVendorsQuery,
} = injectedApi;
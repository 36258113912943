import { Theme } from "@aws-amplify/ui-react";
import antdTheme from "./theme";

const theme: Theme = {
  name: "SIMPLY Theme",
  tokens: {
    components: {
      button: {
        primary: {
          // backgroundColor: antdTheme.token!!.colorPrimary
        }
      }
    }
  }
}

export default theme;
import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { PlaceholderExtension, wysiwygPreset } from 'remirror/extensions';
import { TableComponents, TableExtension } from '@remirror/extension-react-tables';
import { i18nFormat } from '@remirror/i18n';
import { EditorComponent, Remirror, ThemeProvider, useRemirror, useRemirrorContext } from '@remirror/react';
import { AllStyledComponent } from '@remirror/styles/emotion';
import type { CreateEditorStateProps } from 'remirror';
import type { RemirrorProps, UseThemeProps } from '@remirror/react';

import { FloatingToolbar } from '@remirror/react-ui';
import { WysiwygToolbar } from '@remirror/react-ui';

/**
 * Template
 */
import { Alert, Button, Flex, message, Modal, Select } from 'antd';
import { ENDPOINT } from '..';
import { PostAnnouncement } from './CreateAnnouncementModal';

interface Template {
  Id: string;  // internal use only
  Title: string;
  Content: string;
}

/**
 * Bubble menu for the pre-packaged editors
 */
export const BubbleMenu: FC = () => <FloatingToolbar />;


export const TopToolbar: FC = () => <WysiwygToolbar />;



export interface ReactEditorProps
  extends Pick<CreateEditorStateProps, 'stringHandler'>,
    Pick<
      RemirrorProps,
      | 'initialContent'
      | 'editable'
      | 'autoFocus'
      | 'hooks'
      | 'i18nFormat'
      | 'locale'
      | 'supportedLocales'
    > {
  placeholder?: string;
  theme?: UseThemeProps['theme'];
  header?: React.ReactNode;
  postAnnouncement: PostAnnouncement;
  setPostAnnouncement: React.Dispatch<React.SetStateAction<PostAnnouncement>>;
}

export interface WysiwygEditorProps extends Partial<ReactEditorProps> {}

export const WysiwygEditor: FC<PropsWithChildren<WysiwygEditorProps>> = ({
  placeholder,
  stringHandler,
  children,
  theme,
  header,
  postAnnouncement,
  setPostAnnouncement,
  ...rest
}) => {
  const extensions = useCallback(
    () => [new PlaceholderExtension({ placeholder }), new TableExtension(), ...wysiwygPreset()],
    [placeholder],
  );

  const { manager } = useRemirror({ extensions, stringHandler });
  const [templates, setTemplates] = useState<Template[]>([]);
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [lastTemplateName, setLastTemplateName] = useState<string>("");
  const [lastTemplateId, setLastTemplateId] = useState<string | null>(null);
  const [selectedHtml, setSelectedHtml] = useState<string | null>(null);

  async function fetchTemplates() {
    setIsTemplateLoading(true);
    const res = await fetch(ENDPOINT + "/template");
    if (res.ok) {
      const data = await res.json();
      setTemplates(data);
    } else {
      messageApi.error("Failed to fetch templates");
    }
    setIsTemplateLoading(false);
  }

  useEffect(() => {
    fetchTemplates();
  }, []);



  const TemplateHeader = () => {
    const { setContent } = useRemirrorContext();

    function handleSaveTemplate() {
      if (!postAnnouncement) {
        return;
      }

      if (!postAnnouncement.content) {
        messageApi.error("Please fill in the content field");
        return;
      }

      const title = prompt("Enter a title for this template", lastTemplateName);
      if (!title) return;

      const template = { Title: title, Content: postAnnouncement.content };
      fetch(ENDPOINT + "/template", {
        method: "POST",
        body: JSON.stringify(template)
      }).then(res => {
        if (res.ok) {
          messageApi.success("Template saved successfully");
          // setTemplates([...templates, template]);
          fetchTemplates();
        } else {
          messageApi.error("Failed to save template");
        }
      });
    }

    function handleDeleteTemplate() {
      if (!lastTemplateId) {
        return;
      }

      Modal.confirm({
        title: "Delete template",
        content: "Are you sure you want to delete this template?",
        onOk: handleDelete
      })

      function handleDelete() {
        fetch(ENDPOINT + "/template?id=" + lastTemplateId, {
          method: "DELETE"
        }).then(res => {
          if (res.ok) {
            messageApi.success("Template deleted successfully");
            setSelectedHtml(null);
            setLastTemplateId(null);
            setLastTemplateName("");
            fetchTemplates();
          } else {
            messageApi.error("Failed to delete template");
          }
        });
      }
    }

    /**
     * Load content to WYSIWYG editor (from template)
     * @param content HTML content
     */
    function handleUpdateContent() {
      console.log("Updating content", selectedHtml, setPostAnnouncement, postAnnouncement);
      if (!setPostAnnouncement || !selectedHtml || !postAnnouncement) {
        return;
      }

      setPostAnnouncement({ ...postAnnouncement, content: selectedHtml });
      setContent(selectedHtml);
    }

    function handleSelectOnChange(x: { label: string; value: string }) {
      console.log("Selected", x.label);
      if (x.label.trim() !== "") {
        const t = templates.find(t => t.Title === x.label)!!;
        setLastTemplateId(t.Id);
        setLastTemplateName(x.label);
      }
      setSelectedHtml(x.value);
    }
        console.log('last template name', lastTemplateName);

    return <Flex gap={8} align="center" style={{ marginBottom: 12 }}>
      <Select
        labelInValue
        placeholder="Choose a template..."
        defaultValue={selectedHtml !== null ? { label: lastTemplateName, value: selectedHtml } : undefined}
        loading={isTemplateLoading}
        onChange={handleSelectOnChange}
        style={{ width: 200 }}
      >
        {templates.map(t => <Select.Option key={t.Id} value={t.Content}>{t.Title}</Select.Option>)}
      </Select>
      <Button
        type='primary'
        disabled={!selectedHtml}
        onClick={handleUpdateContent}
      >
        Load
      </Button>
      <Button
        type='dashed'
        disabled={lastTemplateId === null}
        onClick={handleDeleteTemplate}
      >
        Delete
      </Button>
      <Button onClick={handleSaveTemplate}>Save as Template</Button>
    </Flex>
  }

  return (
    <AllStyledComponent>
      {contextHolder}
      <ThemeProvider theme={theme}>
        <Remirror manager={manager} i18nFormat={i18nFormat} {...rest}>
          {header}
          <TemplateHeader />

          <TopToolbar />
          <EditorComponent />
          <BubbleMenu />
          <TableComponents />
          {children}
        </Remirror>
      </ThemeProvider>
    </AllStyledComponent>
  );
};
import { Button, Flex, Form, Input, List, message, Modal, ModalProps, Typography, Upload } from "antd";
import { WysiwygEditor } from "@remirror/react-editors/wysiwyg";
import useFetchUsername from "../../../hooks/useFetchUsername";
import { useEffect, useState } from "react";
import { ATTACHMENT_S3_BASE_URL, ATTACHMENT_UPLOAD_ENDPOINT, ENDPOINT } from "..";
import { DeleteTwoTone, InboxOutlined } from "@ant-design/icons";
import handleUploadFile from "../../../utils/uploadFileS3";
import { getFileName } from "./AttachmentsSection";
import { OnChangeHTML } from "@remirror/react";
interface EAMProps {
  announcement: PutAnnouncement;
  visible: boolean;
  onSubmitted: () => void;
  onDismiss: () => void;
}

interface PutAnnouncement {
  id: string;
  title: string;
  content: string;
  attachments: string[];
}

export default function EditAnnouncementModal(props: EAMProps) {
  const uname = useFetchUsername();
  const [putAnnouncement, setPutAnnouncement] = useState<PutAnnouncement>();
  const [messageApi, contextHolder] = message.useMessage();
  const [fileList, setFileList] = useState<any[]>([]);

  useEffect(() => {
    setPutAnnouncement({
      id: props.announcement.id,
      title: props.announcement.title,
      content: props.announcement.content,
      attachments: props.announcement.attachments
    });
    setFileList([]);
  }, [props.announcement, props.visible]);

  async function handleOk() {
    if (putAnnouncement === undefined || !putAnnouncement.title || !putAnnouncement.content) {
      messageApi.error("Please fill in all fields");
      return;
    }

    // Upload new files
    const postResults = [];
    messageApi.loading("Uploading files...");
    for (const f of fileList) {
      const objectId = await handleUploadFile(ATTACHMENT_UPLOAD_ENDPOINT, f);
      postResults.push(objectId);
    }
    messageApi.destroy();
    if (postResults.some(r => r === null)) {
      messageApi.error("Failed to upload files");
      return;
    }

    // Transform objectId to URL
    const tPostResult = postResults.map(r => ATTACHMENT_S3_BASE_URL + r);
    const ann = { ...putAnnouncement, author: uname, attachments: [...putAnnouncement.attachments, ...tPostResult] };
    const res = await fetch(ENDPOINT, {
      method: "PUT",
      body: JSON.stringify(ann)
    });
    if (res.ok) {
      messageApi.success("Announcement updated successfully");
      props.onSubmitted();
      props.onDismiss();
    } else {
      messageApi.error("Failed to edit announcement");
    }
  }

  function handleCancel() {
    props.onDismiss();
  }

  function handleDeleteAttach(file: string) {
    if (putAnnouncement === undefined) {
      return;
    }
    const newAttach = putAnnouncement?.attachments.filter(f => f !== file) ?? [];
    setPutAnnouncement({ ...putAnnouncement, attachments: newAttach });
  }

  return (
    <Modal
      title="Edit Announcement"
      open={props.visible}
      okText="Submit"
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
    >
      {contextHolder}
      <Flex vertical gap={8}>
        <Typography.Text>Title</Typography.Text>
        <Input
          value={putAnnouncement?.title}
          onChange={e => putAnnouncement && setPutAnnouncement({ ...putAnnouncement, title: e.target.value })}
        />
        <Typography.Text>Content</Typography.Text>
        {/* <Input.TextArea
          autoSize={{ minRows: 3 }}
          value={putAnnouncement?.content}
          onChange={e => putAnnouncement && setPutAnnouncement({ ...putAnnouncement, content: e.target.value })}
        /> */}
        { putAnnouncement && (
          <WysiwygEditor initialContent={putAnnouncement?.content} stringHandler="html">
            <OnChangeHTML onChange={html => putAnnouncement && setPutAnnouncement({ ...putAnnouncement, content: html })} />
          </WysiwygEditor>
        )}
        <Typography.Text>Attachments</Typography.Text>
        <List
            bordered
            dataSource={putAnnouncement?.attachments ?? []}
            renderItem={(file) => (
              <List.Item>
                <Typography.Text>{ getFileName(file) }</Typography.Text>
                <Button
                  icon={<DeleteTwoTone />}
                  danger
                  onClick={() => handleDeleteAttach(file)}
                />
              </List.Item>
            )}
          />
        <Typography.Text>Upload New Attachments</Typography.Text>
        <Upload.Dragger
          beforeUpload={f => setFileList([...fileList, f])}
          onRemove={f => setFileList(fileList.filter(file => file.name !== f.name))}
          fileList={fileList}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Upload.Dragger>
      </Flex>
    </Modal>
  )
}
import {
  Text, useTheme, View, Image, Heading,
  Authenticator,
  Flex,
  Grid,
 } from '@aws-amplify/ui-react';
import simplylogo from '../assets/simply-logo.png';
import echoWhite from '../assets/logo-white.png';
import echoCond from '../assets/logo-1.png';
import './style.css';

export const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="Echo Networks Logo"
            src={simplylogo}
            padding={tokens.space.medium}
            height="200px"
            objectFit="contain"
          />
          <br></br>
          <Text
              variation="info"
              as="strong"
              lineHeight="1.5em"
              fontWeight={400}
              fontSize="2em"
              fontStyle="normal"
              textDecoration="none"
              width="30vw"
              color="black"
            >
              EchoNet System 2.0
          </Text>
        </View>


      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading level={4} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
          Sign in to your Account
        </Heading>
        );
      },
    }

  }

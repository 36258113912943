import { Alert, Button, Flex } from "antd";
import useFetchUsername from "../../../hooks/useFetchUsername";
import { useEffect, useState } from "react";
import { Announcement, ENDPOINT } from "..";
import { useLocation, useNavigate } from "react-router-dom";
import checkIfUnread from "../util/checkIfUnread";

/**
 * Used sitewide.
 * Alerts a user and only appears if there are unacknowledged announcements.
 */
export default function AnnouncementBanner() {
  const uname = useFetchUsername();
  const loc = useLocation();
  const nav = useNavigate();
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (!uname) {
      return;
    }
    processUnread();
  }, [uname]);

  async function processUnread() {
    const res = await fetch(ENDPOINT);
    const data = await res.json();
    const unread = data.filter((ann: Announcement) => checkIfUnread(ann, uname)).length;
    setUnreadCount(unread);
  }

  function handleGoToAnnouncements() {
    nav("/announcements");
  }

  const isAnnPage = loc.pathname === "/announcements";

  return (<>
    { unreadCount > 0 && (
      <Flex flex={1} style={{ marginBottom: "1rem" }}>
        <Alert
          action={
            <Button type="link" size="small" onClick={handleGoToAnnouncements}
                    style={{ visibility: isAnnPage ? "hidden" : "visible" }}>
              Read
            </Button>
          }
          message={`You have ${unreadCount} announcement${unreadCount === 1 ? "" : "s"} to acknowledge.`}
          showIcon
          closable
          style={{ flex: 1 }}
        />
      </Flex>
    )}
  </>)
}
import React from "react";
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Space,
  Checkbox,
  Form,
  Input,
  Card,
  Col,
  Row,
  Statistic,
  Flex,
} from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import AnnouncementsCard from "./components/home/AnnouncementsCard";
import InventoryCard from "./components/home/InventoryCard";
import PendingSubmissionCard from "./components/home/PendingSubmissionCard";

export default function Home() {
  return (
    <Flex vertical gap={8}>
      <Row style={{ flex: 1 }}>
        <Col span={24}>
          <AnnouncementsCard />
        </Col>
      </Row>
      <Row gutter={8} style={{ flex: 1 }}>
        <Col span={12}>
          <InventoryCard />
        </Col>
        <Col span={12}>
          <PendingSubmissionCard />
        </Col>
      </Row>
    </Flex>
  );
}

import { configureStore } from "@reduxjs/toolkit";
import { announcementsApi } from "../api/announcementApi";
import { slowboatApi } from "../api/slowboatApi";
import { emptyApi } from "../api/emptyApi";

export const store = configureStore({
  reducer: {
    // Add API reducers here
    [emptyApi.reducerPath]: emptyApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    // Add middleware here
    emptyApi.middleware
  )
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

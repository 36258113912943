import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";

function useFetchUsername() {
    const [uname, setUname] = useState("");

    useEffect(() => {
        const fetchUser = async () => {
            try {
                let session = await Auth.currentUserInfo();
                if (session && session.username) {
                    setUname(session.username);
                }
            } catch (error) {
                console.error("Error fetching user info: ", error);
            }
        };

        fetchUser();
    }, []);

    return uname;
}

export default useFetchUsername;
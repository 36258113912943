import { Button, Flex, Form, Input, message, Modal, ModalProps, Select, Typography, Upload } from "antd";
import useFetchUsername from "../../../hooks/useFetchUsername";
import { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ENDPOINT, ATTACHMENT_UPLOAD_ENDPOINT, ATTACHMENT_S3_BASE_URL } from "..";
import { InboxOutlined } from "@ant-design/icons";
import handleUploadFile from "../../../utils/uploadFileS3";
import { WysiwygEditor } from "./CustomWysiwygEditor";
import { OnChangeHTML, useRemirror, useRemirrorContext } from "@remirror/react";

interface CAMProps {
  visible: boolean;
  onCreated: () => void;
  onDismiss: () => void;
}

export interface PostAnnouncement {
  title: string;
  content: string;
  author: string;
  attachments: string[];
}


export default function CreateAnnouncementModal(props: CAMProps) {
  const uname = useFetchUsername();
  const [postAnnouncement, setPostAnnouncement] = useState<PostAnnouncement>({
    title: "",
    content: "",
    author: "",
    attachments: []
  });
  const [messageApi, contextHolder] = message.useMessage();
  const [fileList, setFileList] = useState<any[]>([]);

  async function handleOk() {
    if (!postAnnouncement.title || !postAnnouncement.content) {
      messageApi.error("Please fill in all fields");
      return;
    }

    const ann = { ...postAnnouncement, author: uname };
    const postResults = [];
    messageApi.loading("Uploading files...");
    for (const f of fileList) {
      const objectId = await handleUploadFile(ATTACHMENT_UPLOAD_ENDPOINT, f);
      console.log("objectId", objectId)
      postResults.push(objectId);
    }
    messageApi.destroy();
    if (postResults.some(r => r === null)) {
      messageApi.error("Failed to upload files");
      return;
    }

    /*
     * Transform objectId to URL
     */
    const tPostResult = postResults.map(r => ATTACHMENT_S3_BASE_URL + r);
    ann.attachments = tPostResult;

    const res = await fetch(ENDPOINT, {
      method: "POST",
      body: JSON.stringify(ann)
    });
    if (res.ok) {
      messageApi.success("Announcement created successfully");
      setPostAnnouncement({ title: "", content: "", author: "", attachments: [] });
      setFileList([]);
      props.onCreated();
      props.onDismiss();
    } else {
      messageApi.error("Failed to create announcement");
    }
  }


  function handleCancel() {
    props.onDismiss();
  }

  return (
    <Modal
      title="Create Announcement"
      open={props.visible}
      okText="Submit"
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
    >
      {contextHolder}
      <Flex vertical gap={8}>
        <Typography.Text>Title</Typography.Text>
        <Input
          value={postAnnouncement.title}
          onChange={e => setPostAnnouncement({ ...postAnnouncement, title: e.target.value })}
        />
        <Typography.Text>Content</Typography.Text>
        {/* <Input.TextArea
          autoSize={{ minRows: 3 }}
          value={postAnnouncement.content}
          onChange={e => setPostAnnouncement({ ...postAnnouncement, content: e.target.value })}
        /> */}

        <WysiwygEditor
          initialContent={postAnnouncement.content}
          stringHandler="html"
          postAnnouncement={postAnnouncement}
          setPostAnnouncement={setPostAnnouncement}
        >
          <OnChangeHTML onChange={html => setPostAnnouncement({ ...postAnnouncement, content: html })} />
        </WysiwygEditor>
        <Typography.Text>Attachments (optional)</Typography.Text>
        <Upload.Dragger
          beforeUpload={f => setFileList([...fileList, f])}
          onRemove={f => setFileList(fileList.filter(file => file.name !== f.name))}
          fileList={fileList}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Upload.Dragger>

      </Flex>
    </Modal>
  )
}